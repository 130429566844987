<template>
  <div class="main-container">
      <h1 class="dark-text fs-18 fw-700" style="margin:25px 0 10px;">Faculty Batch mapping</h1>
      <div class="row">
        <div class="col l3 m6 s12 pl-0 input-box">
      <input class="browser-default pointer" type="text" style="border-radius:4px" placeholder="Search by Faculty Name" v-model="searchValue" id="searchValue"/>
    </div>
        <div class="col l2 m6 s12 pl-0 input-box">
          <select name="" id="select-input" class="browser-default pointer dropdown-list" style="border-radius:5px"
          @change="onCourseFilter($event)" v-model="selectedCourseFilter">
          <option value=0 disabled selected>Filter by Course</option>
          <option :value="data.CourseId" v-for="data in courseData"
            :key="data.CourseId">
            {{ data.CourseName }}
          </option>
        </select>
        </div>
        <div class="col l2 m6 s12 pl-0 input-box">
          <select name="" id="select-input" class="browser-default pointer dropdown-list" style="border-radius:5px"
          v-model="selectedBatchFilter" @change="onBatchFilter($event)">
          <option value=0 disabled selected>Filter by Batch</option>
          <option :value="data.Id" v-for="data in batchData" :key="data.Id">
            {{ data.Name }}
          </option>
        </select>
        </div>
        <div class="col l2 m6 s12 pl-0 input-box">
          <select name="" id="select-input" class="browser-default pointer dropdown-list" style="border-radius:5px" @change="onSubjectFilter($event)"
          v-model="selectedSubjectFilter" >
          <option value=0 disabled selected>Filter by Subject</option>
          <option :value="data.SubjectId" v-for="data in commonSubjectList" :key="data.SubjectId">
            {{ data.SubjectName }}
          </option>
        </select>
        </div>
        <div class="col l1 s12 p-0 input-box">
          <button class="test-btn w-100"
            style="background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%); padding: 13px 10px;"
            @click="clearFilters()" v-if="filterTriggered">Clear</button>
          <button class="test-btn w-100"
            style="background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%); padding: 13px 10px;"
            @click="applyFilters()" v-if="!filterTriggered">Apply</button>
        </div>
        <div class="col l1 s12 p-0 input-box">
          <button class="add-btn w-100" style="background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%);"
            @click="showAddModal = true, resetValues()">Add Faculty</button>
        </div>
      </div>
      <Modal3 :show="showAddModal" :showHeader="false" :showCloseBtn="false" :width="'80%'" >
        <template v-slot:body>
          <div style="padding:10px">
            <div class="flex flex-between publish-head" >
              <span class="fs-16 fw-700" style="margin-top: 2px">Add Faculty</span>
              <div @click="showAddModal=false;" style="cursor: pointer;">
                <span class="material-icons publish-close pointer" style="color: #FFFFFF !important">close</span>
            </div>
            </div>
          <div class="row flex flex-between select-box" >
            <div class="col l6 s12 m-0 p-0" style="margin-left: 0px;margin-right: 5px">
              <label class="fs-14 fw-700 dark-font">Select Course</label>
              <select class="browser-default dropdown-list" style="border-radius:5px"
              @change="onCourseChange($event)" v-model="selectedCourse">
              <option value=0 disabled selected>-- Select --</option>
              <option :value="data.CourseId" v-for="data in courseData"
                :key="data.CourseId">
                {{ data.CourseName }}
              </option>
            </select>
              <p v-if="courseValidation" style="color: red;">
                Please select Course.
            </p>
              </div>
            <div class="col l6 s12 m-0 p-0"  >
              <label class="fs-14 fw-700 dark-font">Select Batch</label>
              <select class="browser-default dropdown-list" style="border-radius:5px"
              v-model="selectedBatch" @change="onBatchChange($event)">
              <option value=0 disabled selected>-- Select --</option>
              <option :value="data.Id" v-for="data in batchData" :key="data.Id">
                {{ data.Name }}
              </option>
            </select>
              <p v-if="batchValidation" style="color: red;">
                Please select Batch.
            </p>
            </div>
          </div >
          <div class="row flex flex-between select-box" >
            <div class="col l6 s12 m-0 p-0" style="margin-left: 0px;margin-right: 5px">
              <label class="fs-14 fw-700 dark-font">Select Subject</label>
              <select class="browser-default dropdown-list" style="border-radius:5px"
              v-model="selectedSubject" @change="onSubjectChange($event)">
             <option value=0 disabled selected>-- Select --</option>
             <option :value="data.SubjectId" v-for="data in commonSubjectList"
               :key="data.SubjectId">
               {{ data.SubjectName }}
             </option>
           </select>
            <p v-if="subjectValidation" style="color: red; ">
              Please select Subject.
          </p>
            </div>
            <div class="col l6 s12 m-0 p-0" >
              <label class="fs-14 fw-700 dark-font">Select Faculty</label>
              <select class="browser-default dropdown-list" style="border-radius:5px"
              v-model="selectedFaculty">
             <option value=0 disabled selected>-- Select --</option>
             <option :value="data.FacultyId" v-for="data in filteredFacultyList"
               :key="data.FacultyId">
               {{ data.FacultyName }}
             </option>
           </select>
            <p v-if="facultyValidation" style="color: red; ">
              Please select Faculty.
          </p>
            </div>
          </div>
            <button @click="addFacultyBatchMapping()" class="publish-modal-button" style="width: 100px;">Add</button>
          </div>
        </template>
    </Modal3>
    <div class="scroll-verticle-bar" style="max-height: calc(100vh - 245px);">
      <div class="white test-list" v-for="(item, index) in filteredRecords" :key="index" style="margin-bottom: 20px;">
        <div class="row test-details flex items-center mb-0">
          <div class="col s12 m6 l2 p-0 ">
            <h2 class="fs-13 fw-600 gray-text-clr line-margin flex items-center" :title="'Course: ' + item.CourseName">
              <img :src="require(`../assets/homework 1.svg`)" alt="" style="margin-right: 6px;"><span class="fw-500">{{item.CourseName}}</span>
            </h2>
          </div>
          <div class="col s12 m6 l3 p-0 ">
            <h2 class="fs-13 fw-600 line-margin gray-text-clr flex items-center duration" :title="'Batch: ' + item.BatchName"><img
              :src="require(`../assets/user-avatar 1.svg`)" alt="" style="margin-right: 6px;" /> <span
              class="fw-500 truncate" style="max-width: 200px;">{{ item.BatchName}}</span></h2>
          </div>
          <div class="col s12 m6 l3 p-0">
            <h2 class="fs-13 fw-600 line-margin gray-text-clr flex items-center duration"><img
                :src="require(`../assets/testHistory-books.svg`)" alt="" style="margin-right: 6px;"><span
                class="block fw-400" style="width:63px">Subject:</span> <span class="fw-500">{{item.SubjectName}}</span>
            </h2>
            </div>
            <div class="col s12 m6 l3 p-0">
            <h2 class="fs-13 fw-600 line-margin gray-text-clr flex items-center duration"><img :src="require(`../assets/Faculty.svg`)" alt=""
                style="margin-right: 6px;"> <span class="block fw-400" style="width:63px">Faculty:</span> <span
                class="fw-500">{{item.FacultyName}}</span></h2>
          </div>
          <div class="col s12 m6 l1 p-0">
            <div class="flex flex-end items-center action-icons">
              <p class="flex fs-14 fw-600 light-gray-text tooltip pointer" style="margin-left: 16px;height: 23px;"
              @click="batchFacultyId = item.BatchFacultyId; showDeleteModal = true">
              <img :src="require(`../assets/classCancel.svg`)" alt="" style="width:23px">
              <span class="tooltiptext">Unmap</span>
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-between white pagination" v-if="filteredRecords.length>0">
      <p style="color: #333333;">Showing <span>1</span> - <span>{{
        filteredRecords.length }}</span> of <span>{{ filteredRecords.length }}</span> Faculty</p>
      </div>
  <div class="text-center" style="margin-bottom: 20px;" v-if="filteredRecords.length === 0">
    <img :src="require(`../assets/no-result-found.png`)" alt="" style="width: 200px; opacity: 0.7;">
    <p class="fw-400 fs-15" style="margin-top: -20px; color: #8c8585;">No details available for selected filter.</p>
</div>
<Modal :show="showDeleteModal" :showHeader="false" @close="showDeleteModal = false">
  <template v-slot:body>
    <div class="warning-section-modal">
      <div class="flex flex-center" style="margin-bottom: 20px;padding-top:30px">
        <img class="" :src="require(`../assets/errorsvg.svg`)" style="width:120px;" />
      </div>
      <div class="flex flex-center" style="flex-direction: column">
        <span class=" mesage-body fs-14 fw-500 dark-text" style="white-space: pre-line;">
          Are you sure you want to unmap this faculty?
        </span>
      </div>
      <div class="flex content-even" style="padding:20px">
        <button class="confirm-btn fs-13 fw-600 ln-18"
          @click="unmapFacultyBatchMapping(batchFacultyId)">YES</button>
        <button class="cancel-btn fs-13 fw-600 ln-18" @click="showDeleteModal = false">NO</button>
      </div>
    </div>
  </template>
</Modal>
  </div>
  </template>
<script>
import { useToast } from "vue-toastification";
import MobileAPI from "../Mobileapi";
import Modal from "../components/Modal.vue";
import Modal3 from "../components/Modal3.vue";

const toast = useToast();

export default {

  data() {
    return {
      filterTriggered: false,
      showAddModal: false,
      showDeleteModal: false,
      batchFacultyId: null,
      courseValidation: false,
      subjectValidation: false,
      facultyValidation: false,
      batchValidation: false,
      courseData: [],
      selectedCourse: 0,
      selectedCourseFilter: 0,
      batchData: [],
      selectedBatch: 0,
      selectedBatchFilter: 0,
      commonSubjectList: [],
      selectedSubject: 0,
      selectedSubjectFilter: 0,
      facultyList: [],
      facultyListBatch: [],
      filteredFacultyList: [],
      selectedFaculty: 0,
      tableRows: [],
      courseFilteredRows: [],
      batchfilteredRows: [],
      searchValue: "",
    };
  },
  components: {
    Modal3,
    Modal,
  },
  computed: {
    // to filter records by name search
    filteredRecords() {
      if (!this.searchValue) {
        return this.tableRows;
      }
      const searchKeyword = this.searchValue.toLowerCase();
      return this.tableRows.filter((row) => {
        const facultyName = row.FacultyName.toLowerCase();
        return facultyName.includes(searchKeyword);
      });
    },
  },
  beforeMount() {
    document.body.style.backgroundColor = "#F6F8FB";
  },
  created() {
    const data = {
      onlyCourses: 1,
      courseId: 0,
      forLps: 0,
    };
    MobileAPI.getCourseMetaData(this.$store.getters.user.AffiliationId, data, (response) => {
      this.$store.dispatch("showLoader", false);
      this.courseData = response.data[0];
      // console.log(response.data[0]);
    });
    this.getFacultyBatchMapping();
  },
  methods: {
    unmapFacultyBatchMapping(batchFacultyId) {
      this.$store.dispatch("showLoader", true);
      MobileAPI.deleteFacultyBatchMapping({ batchFacultyId }, (response) => {
        if (response.responseCode === 200) {
          this.$store.dispatch("showLoader", false);
          this.showDeleteModal = false;
          toast.success("Faculty unmapped successfully", {
            timeout: 2500,
          });
          this.clearFilters();
        } else {
          this.$store.dispatch("showLoader", false);
          toast.info("Some error occured", {
            timeout: 2500,
          });
        }
      });
    },
    getFacultyBatchMapping() {
      MobileAPI.getFacultyBatchMapping({ affiliationId: this.$store.getters.user.AffiliationId }, (res) => {
        this.facultyListBatch = res.data;
        this.tableRows = res.data;
      });
    },
    clearFilters() {
      this.selectedCourseFilter = 0;
      this.selectedSubjectFilter = 0;
      this.selectedBatchFilter = 0;
      this.searchValue = "";
      this.filterTriggered = false;
      this.getFacultyBatchMapping();
    },
    getCourses(selectedCourseId) {
      const data = {
        onlyCourses: 0,
        courseId: selectedCourseId,
      };
      MobileAPI.getCourseMetaData(this.$store.getters.user.AffiliationId, data, (response) => {
        this.$store.dispatch("showLoader", false);
        this.batchData = response.data[1];
        // console.log(response.data[1]);
      });
    },
    onCourseChange() {
      this.commonSubjectList = [];
      this.facultyList = [];
      this.selectedSubject = 0;
      this.selectedBatch = 0;
      this.selectedFaculty = 0;
      this.$store.dispatch("showLoader", true);
      this.getCourses(this.selectedCourse);
    },
    onSubjectChange() {
      this.$store.dispatch("showLoader", true);
      this.selectedFaculty = 0;
      MobileAPI.getFacultyCourseSubjectMapping({ affiliationId: this.$store.getters.user.AffiliationId, subjectId: Number(this.selectedSubject), courseId: this.selectedCourse }, (response) => {
        this.$store.dispatch("showLoader", false);
        this.facultyList = response.data;
        this.selectedFaculty = 0;
        // to not display the faculties already mapped with that batch
        this.filteredFacultyList = this.facultyList.filter((faculty) => {
          const mappedFaculty = this.facultyListBatch.find((item) => item.FacultyId === faculty.FacultyId && item.CourseId === this.selectedCourse && item.BatchId === this.selectedBatch && item.Subjectid === this.selectedSubject);
          return !mappedFaculty;
        });
      });
    },
    onBatchChange() {
      this.$store.dispatch("showLoader", true);
      this.facultyList = [];
      this.selectedSubject = 0;
      this.selectedFaculty = 0;
      MobileAPI.getCommoneSubject({
        Batchids: this.selectedBatch,
      }, (response) => {
        this.commonSubjectList = response.data;
        this.$store.dispatch("showLoader", false);
      });
    },
    onCourseFilter(event) {
      this.$store.dispatch("showLoader", true);
      this.filterTriggered = true;
      this.selectedBatchFilter = 0;
      this.selectedSubjectFilter = 0;
      this.tableRows = this.facultyListBatch;
      this.tableRows = this.tableRows.filter((row) => Number(row.CourseId) === Number(event.target.value));
      const data = {
        onlyCourses: 0,
        courseId: this.selectedCourseFilter,
        forLps: 0,
      };
      MobileAPI.getCourseMetaData(this.$store.getters.user.AffiliationId, data, (response) => {
        this.$store.dispatch("showLoader", false);
        this.batchData = response.data[1];
        // console.log(response.data[1]);
      });
      this.courseFilteredRows = [...this.tableRows];
    },
    onBatchFilter(event) {
      this.$store.dispatch("showLoader", true);
      this.selectedSubjectFilter = 0;
      const rows = [...this.courseFilteredRows];
      this.tableRows = rows.filter((row) => Number(row.BatchId) === Number(event.target.value));
      MobileAPI.getCommoneSubject({
        Batchids: event.target.value,
      }, (response) => {
        this.commonSubjectList = response.data;
        this.$store.dispatch("showLoader", false);
      });
      this.batchfilteredRows = [...this.tableRows];
    },
    onSubjectFilter(event) {
      const rows = [...this.batchfilteredRows];
      this.tableRows = rows.filter((row) => Number(row.Subjectid) === Number(event.target.value));
    },
    resetValues() {
      this.selectedSubject = 0;
      this.selectedCourse = 0;
      this.selectedBatch = 0;
      this.selectedFaculty = 0;
      this.resetValidations();
    },
    resetValidations() {
      this.courseValidation = false;
      this.batchValidation = false;
      this.subjectValidation = false;
      this.facultyValidation = false;
    },
    addFacultyBatchMapping() {
      this.resetValidations();
      if (this.selectedCourse === 0) { this.courseValidation = true; }
      if (this.selectedBatch === 0) { this.batchValidation = true; }
      if (this.selectedSubject === 0) { this.subjectValidation = true; }
      if (this.selectedFaculty === 0) { this.facultyValidation = true; }
      if (!this.courseValidation && !this.batchValidation && !this.subjectValidation && !this.facultyValidation) {
        this.$store.dispatch("showLoader", true);
        const data = {
          subjectId: this.selectedSubject,
          batchId: this.selectedBatch,
          facultyId: this.selectedFaculty,
        };
        MobileAPI.addFacultyBatchMapping(data, (response) => {
          if (response.responseCode === 200) {
            this.showAddModal = false;
            toast.success("Faculty added successfully", {
              timeout: 2500,
            });
            this.resetValues();
            this.clearFilters();
            this.$store.dispatch("showLoader", false);
          } else {
            this.$store.dispatch("showLoader", false);
            toast.info("Some error occured", {
              timeout: 2500,
            });
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.pagination {
  padding: 10px 14px;
  align-items: center;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
p {
  margin: 0;
}
.search-input{
  display: none;
}
.main-container {
  margin-top: 20px;
  padding: 0 10px;
}
.add-btn {
  font-weight: 600;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 5px 18px;
  cursor: pointer;
  margin-left: 10px;
}
.publish-head {
  position: relative;
  width: 100%;
  background: #F3F5FC;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 27px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  /* cursor: pointer; */
}
.publish-close {
  background-color: #FF7675 !important;
  border: 1px solid #E0E4F0;
  border-radius: 3px;
}
.publish-modal-button {
  width: 130px;
  height: 40px;
  background: #37841C;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  border: none;
  margin-right: 30px;
  cursor: pointer;
}
.tooltiptext {
  visibility: hidden;
  background-color: #3fbbb9;
  color: white;
  text-align: center;
  padding: 2px 8px;
  border-radius: 3px;
  z-index: 1;
  white-space: break-spaces;
  text-align: center;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  transition: .3s all ease;
  font-weight: 600;
  position: absolute;
  left: -15px;
  bottom: -32px;
  overflow: visible;
}
.tooltiptext::before {
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  background: #3fbbb9;
  left: 42%;
  top: -5px;
  -webkit-backdrop-filter: #3fbbb9;
  backdrop-filter: #3fbbb9;
  transform: rotate(45deg);
  background: #3fbbb9;
  visibility: hidden;
  transition: .1s all ease;
  line-height: 20px;
}

.tooltip:hover .tooltiptext,
.tooltip:hover .tooltiptext::before {
  visibility: visible;
  overflow: visible;
  display: block;
}
.test-list {
  box-shadow: 4px 4px 10px rgb(100 44 144 / 6%);
  border-radius: 6px;
  padding: 12px 17px;
}
.gray-text-clr {
  color: #333333;
}
.line-margin {
  margin: 7px 0;
}
.test-details {
  display: block !important;
}
.mb-0 {
  margin-bottom: 0;
}
.duration {
  margin-bottom: 0;
}
.action-icons {
  justify-content: center;
}
.light-gray-text {
  color: #333333;
}
.tooltip {
  position: relative;
}
#select-input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url("../assets/Vector4.svg") no-repeat right;
  background-size: 38px 6px;
  height: 43px;
  padding-left: 7px;
}
select option:checked {
  color: #3751FF;
  font-weight: 600;
}

option:disabled {
  color: rgb(170, 170, 170) !important;
  font-weight: 500 !important;
}

select {
  overflow: hidden;
}

select::-webkit-scrollbar {
  width: 0;
}

select option {
  color: #333333 !important;
}

input::-webkit-input-placeholder {
  color: #adadad;
}

input::-moz-placeholder {
  color: #adadad;
}

input::-ms-input-placeholder {
  color: #adadad;
}

input::placeholder {
  color: #adadad;
}
select {
  border: 1px solid #E0E4F0 !important;
  background-color: white !important;
  height: 43px;
  border-radius: 4px;
  font-size: 14px;
  color: #adadad;
}
input {
  border: 1px solid #E0E4F0 !important;
  background-color: white !important;
  height: 43px;
  border-radius: 4px;
  font-size: 14px;
  color: #adadad;
}
.test-btn {
  font-weight: 600;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 9px 13px;
  cursor: pointer;
  background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%);
}
.dark-font {
  color: #333;
}
.date-input {
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  background: #FFFFFF;
  border: 1px solid #E0E4F0;
  border-radius: 4px !important;
  width: 220px !important;
  margin-top: 5px;
}
.test-btn-deactive {
  font-weight: 600;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 9px 13px;
  cursor: pointer;
  background: grey !important;
  pointer-events: none;
}
.warning-section-modal {
  width: 400px;
  /* height: 356px; */
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirm-btn {
  width: 84px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #37841C;
  border-radius: 4px;
  background: #FFFFFF;
  color: #37841C;
  cursor: pointer;
  margin-right: 10px;
}

.confirm-btn:hover {
  background: #37841C !important;
  color: #FFFFFF !important;
}

.cancel-btn {
  width: 84px;
  padding-right: 5px;
  padding-left: 5px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #FF7675;
  border-radius: 4px;
  color: #FF7675;
  cursor: pointer;
  margin-left: 10px;
}

.cancel-btn:hover {
  background: #FF7675 !important;
  color: #FFFFFF !important;
}
.dark-text {
  color: #333333;
}
/* Media Query */
@media screen and (max-width: 992px) {
  .add-btn{
    margin-left:0px
  }
  .input-box {
    margin-bottom: 10px;
  }

  .input-box:nth-child(2),
  .input-box:nth-child(4) {
    padding-right: 0 !important;
  }

  .test-list {
    display: block;
  }

  .action-icons {
    justify-content: flex-start;
    margin-top: 7px;
  }

  .action-icons .tooltip:nth-child(1) {
    margin-left: 0 !important;
  }

  .column-3 {
    clear: left;
  }

  .column-3 h2:nth-child(1) {
    margin-top: 7px;
  }

  .pagination {
    margin-bottom: 10px;
  }

  .test-details {
    display: block !important;
  }

  .end-date {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 600px) {
  .add-btn{
    margin-left:0px
  }
  .input-box {
    padding-right: 0 !important;
  }

  .warning-section-modal {
    width: 300px;
  }

  .duration {
    margin-bottom: 0;
  }

  .start-class {
    justify-content: flex-start !important;
  }

  .course-button {
    height: 28px;
    background: #F3F5FC;
    border: 1px solid #E0E4F0;
    border-radius: 18px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: #333333;
    cursor: pointer;
  }}
</style>
